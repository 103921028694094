import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SettingsBlackFill, SettingsWhiteFill, LightModeWhiteFill, DarkModeBlack, BlogsIcon, BlogsIconWhite } from "../assets/images";
import styles from "../styles/GlobalContentViewStyles.module.css";
import darkStyles from "../styles/DarkGlobalContentViewStyles.module.css";
import Cookies from "js-cookie";
import { updateMetadata } from "../utils/updatemetadata";
import axios from "axios";
import { getApiDomain } from "../config";
import Session from "supertokens-auth-react/recipe/session";

interface ILink {
    name: string;
    onClick: () => void;
    icon: string;
}

export default function HomeContentView() {
    const navigate = useNavigate();
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [firstName, setFirstName] = useState<string | null>(null);

    useEffect(() => {
        const theme = Cookies.get("theme");
        setIsDarkMode(theme === "dark");

        const fetchSessionInfo = async () => {
            if (await Session.doesSessionExist()) {
                try {
                    const response = await axios.get(getApiDomain() + "/sessioninfo");
                    const first_name = response.data.metadata?.first_name;
                    if (first_name) {
                        setFirstName(first_name);
                    }
                } catch (error) {
                    console.error("Error fetching session info:", error);
                }
            }
        };

        fetchSessionInfo();
    }, []);

    async function profileClicked() {
        navigate("/settings");
    }

    function openLink(url: string) {
        window.open(url, "_blank");
    }

    async function toggleTheme() {
        const newTheme = isDarkMode ? "light" : "dark";
        setIsDarkMode(!isDarkMode);
        Cookies.set("theme", newTheme, { expires: 365, sameSite: "None", secure: true });
        await updateMetadata({ preferences: { theme: newTheme } });
    }

    async function onTextEditorClicked() {
        navigate("/text-editor");
    }

    const currentStyles = isDarkMode ? darkStyles : styles;
    const [loadingText, setLoadingText] = useState("Loading");

    useEffect(() => {
        const loadingTexts = ["Loading", "Loading.", "Loading..", "Loading..."];
        let index = 0;
        const interval = setInterval(() => {
            setLoadingText(loadingTexts[index]);
            index = (index + 1) % loadingTexts.length;
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const glitchCharacters = ['#', '$', '%', '&', '!', '@', '*'];
        const originalDate = "20/09/2024";
        let glitchInterval: NodeJS.Timeout;

        const applyGlitchEffect = () => {
            const dateArray = originalDate.split('');
            const randomIndex1 = Math.floor(Math.random() * dateArray.length);
            const randomIndex2 = Math.floor(Math.random() * dateArray.length);
            const randomChar1 = glitchCharacters[Math.floor(Math.random() * glitchCharacters.length)];
            const randomChar2 = glitchCharacters[Math.floor(Math.random() * glitchCharacters.length)];
            dateArray[randomIndex1] = randomChar1;
            dateArray[randomIndex2] = randomChar2;
            setGlitchDate(dateArray.join(''));
        };

        glitchInterval = setInterval(applyGlitchEffect, 1100);

        return () => clearInterval(glitchInterval);
    }, []);

    const [glitchDate, setGlitchDate] = useState("20/09/2024");

    return (
        <>
            <div className={`${currentStyles.mainContainer} home-content-view`}>
                <div className={currentStyles.innerContent}>
                    <h1>SNOWING PINE STORIES - BETA 2.0 LIVE</h1>
                    <h2>Current Version: Beta 2.0.6</h2>
                    <p>While not all features that were planned have been implemented yet, we are at a point where the beta can be safely launched. If you have any feedback or wish to report any bugs, please contact us in discord, patreon, or simply by replying to the signup email!</p>
                    
                    <div className={currentStyles.roadmapContainer}>
                        <h2>Changelog / Roadmap</h2>
                        <ul className={currentStyles.roadmapList}>
                            <li>
                                <span className={currentStyles.checkmark}>✓</span>
                                <span>Migrate to Vercel - (100% done)</span>
                            </li>
                            <li>
                                <span className={currentStyles.checkmark}>✓</span>
                                <span>Implement a New User Authentication Service (Supertokens) - (100% done)</span>
                            </li>
                            <li>
                                <span className={currentStyles.checkmark}>✓</span>
                                <span>Added Progressive Web App (PWA) Functionality - (100% done)</span>
                            </li>
                            <li>
                                <span className={currentStyles.checkmark}>✓</span>
                                <span>Migrated backend from Python Flask to Node.js - (100% done)</span>
                            </li>
                            <li>
                                <span className={currentStyles.checkmark}>✓</span>
                                <span>Migrated frontend to React Typescript - (100% done)</span>
                            </li>
                            <li>
                                <span className={currentStyles.checkmark}>✓</span>
                                <span>Re-Open Beta-Testing (Its Open!~)</span>
                            </li>
                            <li>
                                <span className={currentStyles.checkmark}></span>
                                <span>New Feature Implementation</span>
                                <ul className={`${currentStyles.checkmarkList} ${currentStyles.roadmapSubList}`}>
                                    <li>
                                        <span className={currentStyles.subCheckmark}>✓</span>
                                        <span>Implemented a new, more stable text editor.</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}>✓</span>
                                        <span>Added Update prompt when a new version of the site exists</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}>✓</span>
                                        <span>Created Notifications Backend</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}>✓</span>
                                        <span>Integrated Patreon Chapter Paywall</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}>✓</span>
                                        <span>Fiction Submissions Frontend / Backend</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}>✓</span>
                                        <span>Follow / Favorite Stories</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}>✓</span>
                                        <span>Reader Settings Menu (Light / Dark Mode, Font Size, etc)</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}>✓</span>
                                        <span>User Onboarding / Username Choosing Proccess</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}>✓</span>
                                        <span>Notifications Frontend (New Chapter Notifs). These will properly notify you when a new chapter is posted based on your tier and send you to the appropriate newly unlocked (or latest if you're subbed to the highest tier) chapter. I cannot explain in words how annoying it was to get this to work. I don't usually do long notes on this, but seriously, this was <i>a thing</i>. And all for functional tier-specific notifications. Lmao. Give it some love cause I am certainly never touching this again. It worked as intended when I looked at the code, and it better stay that way until the heat death of the universe.</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}></span>
                                        <span>Feedback Page</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}></span>
                                        <span>Bug Report Page</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}></span>
                                        <span>Blogs Page for Devlogs</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}></span>
                                        <span>Feature Suggestion and Voting Page</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <span className={currentStyles.checkmark}></span>
                                <span>Iron Out the Inevitable Bugs</span>
                            </li>
                            <li>
                                <span className={currentStyles.checkmark}></span>
                                <span>More Features! (For Later)</span>
                                <ul className={`${currentStyles.checkmarkList} ${currentStyles.roadmapSubList}`}>
                                    <li>
                                        <span className={currentStyles.subCheckmark}></span>
                                        <span>Comments on Chapters</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}></span>
                                        <span>Messaging Functionality</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}></span>
                                        <span>And more...</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <span className={currentStyles.checkmark}></span>
                                <span>Official Launch</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={currentStyles.bottomLinksContainer}>
                    <button className={currentStyles.button} onClick={toggleTheme}>
                        <img src={isDarkMode ? LightModeWhiteFill : DarkModeBlack} alt="Toggle Theme" />
                        <span>{isDarkMode ? "Light Mode" : "Dark Mode"}</span>
                    </button>
                </div>
            </div>
        </>
    );
}