import React, { useState, useEffect, useRef } from 'react';
import styles from './ChapterNav.module.css';
import stylesWide from './ChapterNavWide.module.css';
import { useNavigate } from 'react-router-dom';

interface ChapterNavigationProps {
  prevChapterInfo: any;
  nextChapterInfo: any;
  showFictionPageButton?: boolean;
  fictionid: string;
  fictionTitle: string;
  isAuthor: boolean;
  setChapterLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ChapterNavigation({
  prevChapterInfo,
  nextChapterInfo,
  showFictionPageButton = false,
  fictionid,
  fictionTitle,
  isAuthor,
  setChapterLoading
}: ChapterNavigationProps) {
  const navigate = useNavigate();
  const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(false);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const [isFictionPageButtonDisabled, setIsFictionPageButtonDisabled] = useState(false);
  const [oldChapterID, setOldChapterID] = useState<string | null>(null);
  const [showHoverBox, setShowHoverBox] = useState(false);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1400);

  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const hoverBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 1400);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (oldChapterID !== prevChapterInfo.chapterID) {
      setIsPrevButtonDisabled(false);
    }
    if (oldChapterID !== nextChapterInfo.chapterID) {
      setIsNextButtonDisabled(false);
    }
  }, [prevChapterInfo, nextChapterInfo, oldChapterID]);

  useEffect(() => {
    if (showHoverBox && nextButtonRef.current && hoverBoxRef.current) {
      const buttonRect = nextButtonRef.current.getBoundingClientRect();
      hoverBoxRef.current.style.top = `${buttonRect.top}px`;
      hoverBoxRef.current.style.left = `${buttonRect.left + buttonRect.width / 2}px`;
    }
  }, [showHoverBox]);

  const formatTitle = (title: string = '') => {
    const toFormat = title || '';
    const formattedTitle = toFormat.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-').substring(0, 100);
    return formattedTitle;
  };

  const handleNavigation = (chapterInfo: any, setButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>) => {
    setButtonDisabled(true);
    setOldChapterID(chapterInfo.chapterID);
    const formattedFictionTitle = formatTitle(fictionTitle);
    const formattedChapterTitle = formatTitle(chapterInfo.chapterTitle);
    navigate(`/fiction/${fictionid}/${formattedFictionTitle}/chapter/${chapterInfo.chapterID}/${formattedChapterTitle}`, { replace: true });
    setChapterLoading(true);
  };

  const handleFictionPageNavigation = () => {
    setIsFictionPageButtonDisabled(true);
    const formattedFictionTitle = formatTitle(fictionTitle);
    navigate(`/fiction/${fictionid}/${formattedFictionTitle}`, { replace: true });
    setChapterLoading(true);
  };

  const handleNextButtonClick = () => {
    if (!nextChapterInfo.chapterAccessible) {
      setShowHoverBox(true);
      setTimeout(() => setShowHoverBox(false), 1500);
    } else {
      handleNavigation(nextChapterInfo, setIsNextButtonDisabled);
    }
  };

  const handlePreviousButtonClick = () => {
    handleNavigation(prevChapterInfo, setIsPrevButtonDisabled);
  };

  const currentStyles = isWideScreen ? stylesWide : styles;

  if (isWideScreen) {
    return (
      <div className={currentStyles.container}>
        <button 
          onClick={handlePreviousButtonClick}
          disabled={!prevChapterInfo.chapterExists || isPrevButtonDisabled}
          className={`${currentStyles.button} ${currentStyles.previousButton}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={currentStyles.buttonIcon}
          >
            <polyline points="15 18 9 12 15 6" />
          </svg>
          Previous Chapter
        </button>    
        {showFictionPageButton && (
          <button
            onClick={handleFictionPageNavigation}
            disabled={isFictionPageButtonDisabled}
            className={`${currentStyles.button} ${currentStyles.fictionPageButton}`}
          >
            Fiction Page
          </button>
        )}
        <button
          ref={nextButtonRef}
          onClick={handleNextButtonClick}
          disabled={!nextChapterInfo.chapterExists || isNextButtonDisabled}
          className={`${currentStyles.button} ${currentStyles.nextButton}`}
        >
          Next Chapter
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={currentStyles.buttonIcon}
          >
            <polyline points="9 18 15 12 9 6" />
          </svg>
        </button>
        {showHoverBox && (
          <div ref={hoverBoxRef} className={currentStyles.hoverBox}>
            Only accessible to higher tier Patrons
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={currentStyles.container}>
        <div className={currentStyles.topRow}>
          <button 
            onClick={handlePreviousButtonClick}
            disabled={!prevChapterInfo.chapterExists || isPrevButtonDisabled}
            className={`${currentStyles.button} ${currentStyles.previousButton}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className={currentStyles.buttonIcon}
            >
              <polyline points="15 18 9 12 15 6" />
            </svg>
            Previous Chapter
          </button>    
          <button
            ref={nextButtonRef}
            onClick={handleNextButtonClick}
            disabled={!nextChapterInfo.chapterExists || isNextButtonDisabled}
            className={`${currentStyles.button} ${currentStyles.nextButton}`}
          >
            Next Chapter
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className={currentStyles.buttonIcon}
            >
              <polyline points="9 18 15 12 9 6" />
            </svg>
          </button>
        </div>
        {showFictionPageButton && (
          <div className={currentStyles.topRow}>
            <button
              onClick={handleFictionPageNavigation}
              disabled={isFictionPageButtonDisabled}
              className={`${currentStyles.button} ${currentStyles.fictionPageButton}`}
            >
              Fiction Page
            </button>
          </div>
        )}
        {showHoverBox && (
          <div ref={hoverBoxRef} className={currentStyles.hoverBox}>
            Only accessible to higher tier Patrons
          </div>
        )}
      </div>
    );
  }
}