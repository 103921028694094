import { useState, useEffect, useCallback } from "react";
import { SettingsBlackFill, SettingsWhiteFill, LightModeWhiteFill, DarkModeBlack, BlogsIcon, BlogsIconWhite } from "../assets/images";
import styles from "../styles/GlobalContentViewStyles.module.css";
import darkStyles from "../styles/DarkGlobalContentViewStyles.module.css";
import localStyles from "./OnboardingLight.module.css";
import darkLocalStyles from "./OnboardingDark.module.css";
import Cookies from "js-cookie";
import { updateMetadata } from "../utils/updatemetadata";
import axios from "axios";
import { getApiDomain } from "../config";
import Session from "supertokens-auth-react/recipe/session";
import Loading from "../components/Loading";

export default function HomeContentView() {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [username, setUsername] = useState<string>(""); // Updated to match the new type
    const [usernameExists, setUsernameExists] = useState<boolean | null>(null); // Added new state
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null); // Added new state
    const [usernameError, setUsernameError] = useState<string | null>(null); // Added new state
    const [isCheckingUsername, setIsCheckingUsername] = useState<boolean>(false); // Added new state
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [usernameAlreadyChosen, setUsernameAlreadyChosen] = useState(false);

    useEffect(() => {
        const theme = Cookies.get("theme");
        setIsDarkMode(theme === "dark");

        const fetchSessionInfo = async () => {
            if (await Session.doesSessionExist()) {
                try {
                    const response = await axios.get(getApiDomain() + "/sessioninfo");
                    const choseUsername = response.data.metadata?.chose_username;
                    if (choseUsername) {
                        setUsernameAlreadyChosen(true);
                        setFinishedLoading(true);
                    } else {
                        setFinishedLoading(true);
                    }

                } catch (error) {
                    console.error("Error fetching session info:", error);
                }
            }
        };

        fetchSessionInfo();
    }, []);

    const checkUsernameExists = useCallback(async (username: string) => {
        setIsCheckingUsername(true);
        try {
            const response = await axios.get(`${getApiDomain()}/user/usernameexists`, { params: { username } });
            setUsernameExists(response.data.exists);
        } catch (error) {
            console.error("Error checking username:", error);
        } finally {
            setIsCheckingUsername(false);
        }
    }, []);

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newUsername = e.target.value;
        setUsername(newUsername);

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        if (newUsername.length <= 4 || /[^a-zA-Z0-9. ]/.test(newUsername) || /\s{2,}/.test(newUsername)) {
            setUsernameError("Username must be at least 5 characters long. Single spaces and periods are also allowed.");
            setUsernameExists(null);
        } else {
            setUsernameError(null);
            setTypingTimeout(setTimeout(() => {
                checkUsernameExists(newUsername);
            }, 200));
        }
    };

    const handleSaveUsername = async () => {
        if (usernameError) {
            alert(usernameError);
            return;
        }

        try {
            const response = await axios.get(`${getApiDomain()}/user/saveusername`, { params: { username } });
            if (response.status === 200) {
                await updateMetadata({ first_name: username, chose_username: true, username: username });
                const userId = response.data.userId;
                Cookies.set(`choseUsername_${userId}`, 'true', { expires: 365, sameSite: "None", secure: true });
                window.location.assign('/');
            }
        } catch (error) {
            console.error("Error saving username:", error);
        }
    };

    const currentStyles = isDarkMode ? darkStyles : styles;
    const currentLocalStyles = isDarkMode ? darkLocalStyles : localStyles;

    if (!finishedLoading) {
        return <Loading />;
    };

    return (
        <>
            <div className={`${currentStyles.mainContainer} home-content-view`}>
                <div className={currentStyles.innerContent}>
                    {usernameAlreadyChosen === false ? (
                        <>
                            <h1>Welcome to Snowing Pine!</h1>
                            <p>Please choose your username below. There will be a function added in the future to change it, but right not it does not exist</p>
                            <input
                                type="text"
                                className={currentLocalStyles.usernameInput}
                                value={username}
                                onChange={handleUsernameChange}
                                placeholder="Enter your username"
                            />
                            {usernameError && (
                                <p className={currentLocalStyles.errorMessage}>{usernameError}</p>
                            )}
                            {isCheckingUsername && (
                                <p className={currentLocalStyles.checkingMessage}>Checking...</p>
                            )}
                            {usernameExists === false && (
                                <div>
                                    <p className={currentLocalStyles.successMessage}>Username is available!</p>
                                    <button className={currentLocalStyles.saveButton} onClick={handleSaveUsername}>Save</button>
                                </div>
                            )}
                            {usernameExists === true && (
                                <p className={currentLocalStyles.errorMessage}>Username is already taken.</p>
                            )}
                        </>
                    ) : (
                        <>
                            <h1>Username Already Chosen</h1>
                            <p>You have already chosen a username. A function to change it will be provided at a later date. If you believe you made a mistake, please contact an admin.</p>
                            <button className={currentLocalStyles.saveButton} onClick={() => window.location.assign('/')}>Go Home</button>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
