import React from 'react';
import styles from './ChangelogModal.module.css';
import darkStyles from './ChangelogModalDark.module.css';

interface ChangelogModalProps {
    onClose: () => void;
    changelog: {
        id: number;
        version: string;
        title: string;
        description: string;
        date: string;
    };
    theme: string;
}

const ChangelogModal: React.FC<ChangelogModalProps> = ({ onClose, changelog, theme }) => {
    const modalContentClass = theme === 'dark' ? darkStyles.modalContent : styles.modalContent;

    const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className={styles.modalOverlay} onClick={handleOverlayClick}>
            <div className={modalContentClass}>
                <button className={styles.closeButton} onClick={onClose}>
                    &times;
                </button>
                <small>Version "{changelog.version}"</small>
                <h2>{changelog.title}</h2>
                <div className={styles.modalBody} style={{ paddingRight: '5px' }}>
                    <div dangerouslySetInnerHTML={{ __html: changelog.description }} />
                </div>
                <small>{new Date(changelog.date).toLocaleDateString()}</small>
            </div>
        </div>
    );
};

export default ChangelogModal;