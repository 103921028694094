import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import { SettingsBlackFill, SettingsWhiteFill, LightModeWhiteFill, DarkModeBlack, BlogsIcon, BlogsIconWhite, bg3 } from "../assets/images";
import styles from "../styles/GlobalContentViewStyles.module.css";
import darkStyles from "../styles/DarkGlobalContentViewStyles.module.css";
import Cookies from "js-cookie";
import { updateMetadata } from "../utils/updatemetadata";
import axios from "axios";
import { getApiDomain } from "../config";
import Session from "supertokens-auth-react/recipe/session";
import lightHomeStyles from "./LightFictionPage.module.css";
import darkHomeStyles from "./DarkFictionPage.module.css";
import MiniLoading from "../components/MiniLoading";
import FictionInfo from "./FictionInfo";
import TableOfContents from "./TableOfContents";

export default function FictionContentView() {
    const navigate = useNavigate();
    const { fictionid } = useParams();
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [firstName, setFirstName] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fictionData, setFictionData] = useState<any>(null);
    const [fictionLoading, setFictionLoading] = useState(true);
    const [chaptersLoading, setChaptersLoading] = useState(true);
    const [chapterList, setChapterList] = useState<any>(null);
    const [earliestChapter, setEarliestChapter] = useState<any>(null);
    const [userId, setUserId] = useState<string | null>(null);
    const [coverImageUrl, setCoverImageUrl] = useState<string | null>(`${getApiDomain()}/api/fictions/imagefile/${fictionid}.jpg`);
    const [isFollowing, setIsFollowing] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);
    const [followFavoriteLoading, setFollowFavoriteLoading] = useState(false);
    const [isAlreadyReading, setIsAlreadyReading] = useState(false);
    const [currentChapter, setCurrentChapter] = useState<number | null>(null);
    const [patronInfo, setPatronInfo] = useState<any>(null);
    const [patronInfoLoading, setPatronInfoLoading] = useState(true);
    const [readChapters, setReadChapters] = useState<string[]>([]);

    useEffect(() => {
        const theme = Cookies.get("theme");
        setIsDarkMode(theme === "dark");

        const fetchSessionInfo = async () => {
            if (await Session.doesSessionExist()) {
                try {
                    const response = await axios.get(getApiDomain() + "/sessioninfo");
                    const username = response.data.metadata?.username;
                    const readingProgress = response.data.metadata?.reading_progress;
                    const progress = readingProgress ? readingProgress[fictionid as keyof typeof readingProgress] : null;
                    if (progress) {
                        console.log(`Reading progress for fiction ID ${fictionid}: ${progress}`);
                        setCurrentChapter(parseInt(progress, 10))
                        setIsAlreadyReading(true);
                    } else {
                        setCurrentChapter(null);
                    }
                    
                    let readChapters = response.data.metadata?.read_chapters;
                    const readChapterIDs = readChapters ? Object.values(readChapters) as string[] : [];
                    console.log('readChapterIDs', readChapterIDs);
                    setReadChapters(readChapterIDs);

                    const user_id = response.data.userId;
                    if (username) {
                        setFirstName(username);
                    }
                    if (user_id) {
                        setUserId(user_id);
                    }
                } catch (error) {
                    console.error("Error fetching session info:", error);
                }
            }
        };

        const fetchFictionInfo = async () => {
            try {
                const response = await axios.get(`${getApiDomain()}/api/fictions/textinfo`, { params: { id: fictionid } });
                setFictionData(response.data);
                setError(null);
            } catch (error) {
                console.error('Error fetching fiction info:', error);
                setError('Error fetching fiction info. Please try again later.');
            } finally {
                setFictionLoading(false);
            }
        };

        const fetchChapterList = async () => {
            try {
                const response = await axios.get(`${getApiDomain()}/api/fictions/chapterlist`, { params: { id: fictionid } });
                setChapterList(response.data?.chapters);
                setError(null);
                const earliestChapter = response.data?.chapters.reduce((earliest: any, current: any) => {
                    return current.publish_time < earliest.publish_time ? current : earliest;
                }, response.data?.chapters[0]);

                setEarliestChapter(earliestChapter.id);
            } catch (error) {
                console.error('Error fetching chapter list:', error);
                setError('Error fetching chapter list. Please try again later.');
            } finally {
                setChaptersLoading(false);
            }
        };

        fetchSessionInfo();
        fetchFictionInfo();
        fetchChapterList();
    }, [fictionid]);

    useEffect(() => {
        if (fictionData) {
            const formatTitle = (title: string) => {
                return title.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-').substring(0, 100);
            };

            if (userId) {
                console.log("User ID:", userId);

                if (fictionData.followers.includes(userId)) {
                    setIsFollowing(true);
                    console.log("User is following this fiction");
                }
                if (fictionData.favorited_by.includes(userId)) {
                    setIsFavorite(true);
                }
            }

            const formattedFictionTitle = formatTitle(fictionData.title);

            navigate(`/fiction/${fictionid}/${formattedFictionTitle}`, { replace: true });
        }
    }, [fictionData, fictionid, navigate, coverImageUrl, userId]);

    useEffect(() => {
        const fetchPatronInfo = async () => {
            if (await Session.doesSessionExist()) {
                try {
                    const response = await axios.get(getApiDomain() + "/user/patroninfo", {
                        params: { patreon: fictionData.patreon }
                    });
                    const patronInfo = response.data;
                    if (patronInfo.exists) {
                        setPatronInfo(patronInfo.data);
                        console.log("Patron Info:", patronInfo.data);
                    }
                } catch (error) {
                    console.error("Error fetching patron info:", error);
                } finally {
                    setPatronInfoLoading(false);
                }
            }
        };

        if (fictionData?.patreon) {
            fetchPatronInfo();
        }
    }, [fictionData]);

    async function requestNotificationPermission() {
        try {
            console.log("Requesting notification permission");
            const permission = await Notification.requestPermission();
            if (permission !== 'granted') {
                console.error("Notification permission not granted");
                return;
            }
            console.log("Notification permission granted");

            const registration = await navigator.serviceWorker.ready;
            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: 'BIINfx4wWl_arxT3Q2_Q1aOQY-9eezGXyyoFdQPG937DEUImLK4MvCITXdvjTXI0ylDVw2FAjBtc-bpp5kQXZGM'
            });

            console.log('Push subscription:', subscription);

            await axios.post(getApiDomain() + "/api/push_subscription_data", subscription);

            console.log("Subscription data sent to server");
        } catch (error) {
            console.error("Error requesting notification permission or subscribing:", error);
        }
    }

    const toggleFollow = async () => {
        setFollowFavoriteLoading(true);
        try {
            const response = await axios.post(`${getApiDomain()}/api/fictions/follow`, null, { params: { id: fictionid } });
            setIsFollowing(response.data.isFollowing);
            if (response.data.isFollowing) {
                await requestNotificationPermission();
            }

        } catch (error) {
            console.error('Error toggling follow status:', error);
            setError('Error toggling follow status. Please try again later.');
        } finally {
            setFollowFavoriteLoading(false);
        }
    };

    const toggleFavorite = async () => {
        setFollowFavoriteLoading(true);
        try {
            const response = await axios.post(`${getApiDomain()}/api/fictions/favorite`, null, { params: { id: fictionid } });
            setIsFavorite(response.data.isFavorited);
            setFollowFavoriteLoading(false);
        } catch (error) {
            console.error('Error toggling favorite status:', error);
            setError('Error toggling favorite status. Please try again later.');
        } finally {
            setFollowFavoriteLoading(false);
        }
    };

    const currentStyles = isDarkMode ? darkStyles : styles;
    const localCurrentStyles = isDarkMode ? darkHomeStyles : lightHomeStyles;

    if (error) {
        return <div className="error">{error}</div>;
    }

    return (
        <>
            {fictionData && (
                <HelmetProvider>
                    <Helmet>
                        <title>{fictionData.title} | Snowing Pine Stories</title>
                        <meta name="description" content={fictionData.synopsis.split('\n').slice(0, 3).join(' ').substring(0, 200)} />
                        <meta property="og:title" content={fictionData.title} />
                        <meta property="og:description" content={fictionData.synopsis.split('\n').slice(0, 3).join(' ').substring(0, 200)} />
                        <meta property="og:image" content={coverImageUrl || ''} />
                        <meta property="og:url" content={window.location.href} />
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:title" content={fictionData.title} />
                        <meta name="twitter:description" content={fictionData.synopsis.split('\n').slice(0, 3).join(' ').substring(0, 200)} />
                        <meta name="twitter:image" content={coverImageUrl || ''} />
                        <meta name="author" content={fictionData.user.full_name} />
                    </Helmet>
                </HelmetProvider>
            )}
            <div className={`${localCurrentStyles.mainContainer} home-content-view`}>
                {fictionLoading ? (
                    <div style={{ margin: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <MiniLoading />
                    </div>
                ) : (
                    <>
                        <FictionInfo
                            isDarkMode={isDarkMode}
                            title={fictionData.title}
                            author={fictionData.user.full_name}
                            coverImage={coverImageUrl || ''}
                            description={fictionData.synopsis}
                            fictionId={fictionid || ''}
                            earliestChapter={earliestChapter}
                            isAuthor={fictionData.user.id === userId}
                            isAlreadyReading={isAlreadyReading}
                            currentChapter={currentChapter ?? earliestChapter}
                            patreon={fictionData.patreon}
                            isFollowed={isFollowing}
                            isFavorite={isFavorite}
                            toggleFollow={toggleFollow}
                            toggleFavorite={toggleFavorite}
                            followFavoriteLoading={followFavoriteLoading}
                            // tags prop can be omitted if there are no tags
                        />
                    </>
                )}
                {(chaptersLoading || fictionLoading) ? (
                    <div style={{ margin: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <MiniLoading />
                    </div>
                ) : (
                    <TableOfContents patronInfoLoading={patronInfoLoading} patronInfo={patronInfo} isDarkMode={isDarkMode} fictionData={fictionData} items={chapterList} fictionId={fictionid || ''} title={fictionData.title} readChapters={readChapters}/>
                )}
                {/* <div className={currentStyles.bottomLinksContainer}>
                    {links.map((link) => (
                        <div className={currentStyles.button} key={link.name} role={"button"} onClick={link.onClick}>
                            <img className={currentStyles.linkIcon} src={link.icon} alt={link.name} />
                            <div>
                                {link.name}
                            </div>
                        </div>
                    ))}
                    <button className={currentStyles.button} onClick={toggleTheme}>
                        <img src={isDarkMode ? LightModeWhiteFill : DarkModeBlack} alt="Toggle Theme" />
                        <span>{isDarkMode ? "Light Mode" : "Dark Mode"}</span>
                    </button>
                </div> */}
            </div>
        </>
    );
}