import React, { useState, useRef, useEffect } from 'react'
import { ChevronDown, ChevronUp, Lock, LockOpen  } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import styles from './TableOfContents.module.css'
import stylesDark from './TableOfContentsDark.module.css'
import Cookies from 'js-cookie'

interface TableOfContentsItem {
  title: string
  id: number
  publish_time: number
  always_public: boolean
}

interface TierSetting {
  id: string
  title: string
  early_chapter_count: number
}

interface PatronInfo {
  id: string
  email: string
  full_name: string
  campaign_id: string
  patron_status: string
  currently_entitled_amount_cents: number
  lifetime_support_cents: number
  last_charge_date: string
  last_charge_status: string
  next_charge_date: string
  pledge_relationship_start: string
  pledge_cadence: number
  will_pay_amount_cents: number
  tier_id: string
  tier_title: string
  created_at: string
  is_paying: boolean
}

interface TableOfContentsProps {
  items?: TableOfContentsItem[]
  fictionId: string
  title: string
  isDarkMode: boolean
  fictionData: {
    tiers_settings: TierSetting[]
  }
  patronInfo: PatronInfo
  patronInfoLoading: boolean
  readChapters: string[]
}

const defaultItems: TableOfContentsItem[] = []

type SortField = 'title' | 'publish_time'
type SortOrder = 'asc' | 'desc'

export default function TableOfContents({ items = defaultItems, fictionId, title, isDarkMode, fictionData, patronInfo, patronInfoLoading, readChapters}: TableOfContentsProps) {
  const [entriesPerPage, setEntriesPerPage] = useState(10)
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [sortField, setSortField] = useState<SortField>('publish_time')
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc')
  const navigate = useNavigate()
  const sterilizedTitle = title.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-').substring(0, 100);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  // Determine the highest early_chapter_count (x)
  const maxEarlyChapterCount = Math.max(...fictionData.tiers_settings.map(tier => tier.early_chapter_count))

  // Get the latest chapters based on publish_time descending
  const sortedChapters = [...items].sort((a, b) => b.publish_time - a.publish_time)

  // Find the chapter at the position of maxEarlyChapterCount
  const targetChapter = sortedChapters.filter(chapter => !chapter.always_public)[maxEarlyChapterCount - 1]

  // Create a map of chapter ID to required early_chapter_count
  const lockedChaptersMap: { [key: number]: number } = {}
  const filteredSortedChapters = sortedChapters.filter(chapter => !chapter.always_public)
  for (let i = maxEarlyChapterCount - 1; i >= 0; i--) {
    const chapter = filteredSortedChapters[i]
    if (chapter) {
      lockedChaptersMap[chapter.id] = maxEarlyChapterCount - i
    }
  }

  // Filter items based on search term
  const filteredItems = items.filter(item =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  )

  // Sort items based on sortField and sortOrder
  const sortedItems = [...filteredItems].sort((a, b) => {
    if (sortField === 'title') {
      return sortOrder === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title)
    } else {
      return sortOrder === 'asc' ? a.publish_time - b.publish_time : b.publish_time - a.publish_time
    }
  })

  const totalPages = Math.ceil(sortedItems.length / entriesPerPage)
  const paginatedItems = sortedItems.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  )

  const formatDate = (timestamp: number) => {
    const diff = timestamp - Date.now()
    const isFuture = diff > 0
    const absDiff = Math.abs(diff)
    const seconds = Math.floor(absDiff / 1000) % 60
    const minutes = Math.floor(absDiff / (1000 * 60)) % 60
    const hours = Math.floor(absDiff / (1000 * 60 * 60)) % 24
    const days = Math.floor(absDiff / (1000 * 60 * 60 * 24)) % 7
    const weeks = Math.floor(absDiff / (1000 * 60 * 60 * 24 * 7)) % 4
    const months = Math.floor(absDiff / (1000 * 60 * 60 * 24 * 30))

    if (months > 0) {
      return isFuture ? `in ${months} month${months > 1 ? 's' : ''}` : `${months} month${months > 1 ? 's' : ''} ago`
    } else if (weeks > 0) {
      return isFuture ? `in ${weeks} week${weeks > 1 ? 's' : ''}` : `${weeks} week${weeks > 1 ? 's' : ''} ago`
    } else if (days > 0) {
      return isFuture ? `in ${days} day${days > 1 ? 's' : ''}` : `${days} day${days > 1 ? 's' : ''} ago`
    } else if (hours > 0) {
      return isFuture ? `in ${hours} hour${hours > 1 ? 's' : ''}` : `${hours} hour${hours > 1 ? 's' : ''} ago`
    } else if (minutes > 0) {
      return isFuture ? `in ${minutes} minute${minutes > 1 ? 's' : ''}` : `${minutes} minute${minutes > 1 ? 's' : ''} ago`
    } else {
      return isFuture ? `in ${seconds} second${seconds > 1 ? 's' : ''}` : `${seconds} second${seconds > 1 ? 's' : ''} ago`
    }
  }

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortField(field)
      setSortOrder('asc')
    }
  }

  const SortIcon = ({ field }: { field: SortField }) => {
    if (sortField !== field) return null
    return sortOrder === 'asc' ? <ChevronUp className="h-4 w-4 inline-block ml-1" /> : <ChevronDown className="h-4 w-4 inline-block ml-1" />
  }

  const handleChapterClick = (item: TableOfContentsItem) => {
    const sterilizedItemTitle = item.title.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-').substring(0, 100);
    navigate(`/fiction/${fictionId}/${sterilizedTitle}/chapter/${item.id}/${sterilizedItemTitle}`);
  }

  const appliedStyles = isDarkMode ? stylesDark : styles

  // Function to get tiers that can access a locked chapter
  const getAccessibleTiers = (requiredCount: number): string[] => {
    return fictionData.tiers_settings
      .filter(tier => tier.early_chapter_count >= requiredCount)
      .map(tier => tier.title)
  }

  const getAccessibleTierIDs = (requiredCount: number): string[] => {
    return fictionData.tiers_settings
      .filter(tier => tier.early_chapter_count >= requiredCount)
      .map(tier => tier.id)
  }

  const [hoveredItem, setHoveredItem] = useState<TableOfContentsItem | null>(null);
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });
  const hoverBoxRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = (item: TableOfContentsItem, event: React.MouseEvent) => {
    if (item.always_public) {
      setHoveredItem(null);
    } else {
      const accessibleTiers = getAccessibleTiers(lockedChaptersMap[item.id] || 0);
      if (accessibleTiers.includes("Free")) {
        setHoveredItem(null);
      } else {
        setHoveredItem(item);
        const rect = event.currentTarget.getBoundingClientRect();
        setHoverPosition({ x: rect.left, y: rect.top });
      }
    }
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  // Get cookie readChapterIDs if readChapters is an empty array
  const readChapterIDs = readChapters.length === 0 ? JSON.parse(Cookies.get('readChapterIDs') || '[]') : readChapters;

  return (
    <div className={appliedStyles.container}>
      <div className={appliedStyles.header}>
        <h2 className={appliedStyles.title}>
          <svg className={appliedStyles.titleIcon} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
          </svg>
          TABLE OF CONTENTS
        </h2>
        <span className={appliedStyles.chapterCount}>{items.length} {items.length === 1 ? 'Chapter' : 'Chapters'}</span>
      </div>
      <div className={appliedStyles.controls}>
        <div className={appliedStyles.selectWrapper}>
          <select
            className={appliedStyles.select}
            value={entriesPerPage}
            onChange={(e) => setEntriesPerPage(Number(e.target.value))}
          >
            <option>10</option>
            <option>20</option>
            <option>50</option>
            <option>100</option>
          </select>
          <div className={appliedStyles.selectIcon}>
            <ChevronDown className="h-4 w-4" />
          </div>
        </div>
        <input
          type="text"
          placeholder="Search..."
          className={appliedStyles.searchInput}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <table className={appliedStyles.table}>
        <thead>
          <tr className={appliedStyles.tableHeader}>
            <th className={appliedStyles.tableHeaderCell} onClick={() => handleSort('title')}>
              Chapter Name
              <SortIcon field="title" />
            </th>
            <th className={`${appliedStyles.tableHeaderCell} ${appliedStyles.tableHeaderCellRight}`} onClick={() => handleSort('publish_time')}>
              Release Date
              <SortIcon field="publish_time" />
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedItems.map((item) => {
            const sterilizedItemTitle = item.title.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-').substring(0, 100);
            const href = `/fiction/${fictionId}/${sterilizedTitle}/chapter/${item.id}/${sterilizedItemTitle}`;
            
            const isLocked = !item.always_public && lockedChaptersMap[item.id] !== undefined
            const accessibleTiers = isLocked ? getAccessibleTiers(lockedChaptersMap[item.id]) : []
            const accessibleTierIDs = isLocked ? getAccessibleTierIDs(lockedChaptersMap[item.id]) : []
            const isUnlocked = !patronInfoLoading && patronInfo && accessibleTierIDs.includes(patronInfo.tier_id)
            
            const isRead = readChapterIDs.includes(item.id.toString());

            return (
              <tr
                key={item.id}
                className={appliedStyles.tableRow}
                onMouseEnter={(e) => handleMouseEnter(item, e)}
                onMouseLeave={handleMouseLeave}
              >
                <td className={`${appliedStyles.tableCell} ${appliedStyles.chapterTitle}`} style={{ color: isRead ? 'gray' : 'inherit' }}>
                  {isLocked && (isUnlocked ? <LockOpen className="h-4 w-4 inline-block mr-2" style={{ maxWidth: '15px', paddingRight: '5px' }} /> : <Lock className="h-4 w-4 inline-block mr-2" style={{ maxWidth: '15px', paddingRight: '5px' }} />)}
                  <a onClick={(e) => { e.preventDefault(); navigate(href); }} style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}>{item.title}</a>
                </td>
                <td className={`${appliedStyles.tableCell} ${appliedStyles.releaseDate}`}>{formatDate(item.publish_time)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {hoveredItem && !isMobile && (
        <div
          ref={hoverBoxRef}
          className={appliedStyles.hoverBox}
          style={{ position: 'absolute', top: `${hoverPosition.y - 40}px`, left: `${hoverPosition.x}px`, zIndex: 1000, display: 'block' }}
        >
          {getAccessibleTiers(lockedChaptersMap[hoveredItem.id] || 0).includes("Free")
            ? null
            : getAccessibleTiers(lockedChaptersMap[hoveredItem.id] || 0).concat("Free").sort().join(', ') === fictionData.tiers_settings.map(tier => tier.title).sort().join(', ') 
              ? <span><strong>Unlocked for</strong> all Patrons</span>
              : <span><strong>Unlocked for:</strong> {getAccessibleTiers(lockedChaptersMap[hoveredItem.id] || 0).join(', ')}</span>}
          <div className={appliedStyles.hoverBoxArrow}></div>
        </div>
      )}
      <div className={appliedStyles.pagination}>
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            onClick={() => setCurrentPage(page)}
            className={`${appliedStyles.pageButton} ${currentPage === page ? appliedStyles.pageButtonActive : ''}`}
          >
            {page}
          </button>
        ))}
        {/* {currentPage < totalPages && (
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            className={appliedStyles.nextPageButton}
          >
            <ChevronRight className="h-4 w-4" />
          </button>
        )} */}
      </div>
    </div>
  )
}