import React from 'react';
import styles from './NotificationPermissionModal.module.css';

interface NotificationPermissionModalProps {
    onRequestPermission: () => void;
    onClose: () => void;
    theme: string;
}

const NotificationPermissionModal: React.FC<NotificationPermissionModalProps> = ({ onRequestPermission, onClose, theme }) => {
    const modalClass = theme === 'dark' ? `${styles.modal} ${styles.darkMode}` : `${styles.modal} ${styles.lightMode}`;
    const modalContentClass = styles.modalContent;

    return (
        <div className={modalClass}>
            <div className={modalContentClass}>
                <h2>Notification Permission</h2>
                <p>Click "Grant" if you want to receive notifications for new chapter releases.</p>
                <p>Notifications are only received about fictions in your "Follow" list.</p>
                <button onClick={onRequestPermission}>Grant Permission</button>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default NotificationPermissionModal;