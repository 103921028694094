import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { SettingsBlackFill, SettingsWhiteFill, LightModeWhiteFill, DarkModeBlack, BlogsIcon, BlogsIconWhite, bg3 } from "../assets/images";
import styles from "../styles/GlobalContentViewStyles.module.css";
import darkStyles from "../styles/DarkGlobalContentViewStyles.module.css";
import Cookies from "js-cookie";
import { updateMetadata } from "../utils/updatemetadata";
import axios from "axios";
import { getApiDomain } from "../config";
import Session from "supertokens-auth-react/recipe/session";
import lightHomeStyles from "./LightHome.module.css";
import darkHomeStyles from "./DarkHome.module.css";
import MiniLoading from "../components/MiniLoading";

interface ILink {
    name: string;
    onClick: () => void;
    icon: string;
}

interface Fiction {
    id: number;
    cover: string;
    title: string;
    chapters: number;
    comments: number;
    placeholder1: number;
    placeholder2: number;
}

interface Announcement {
    id: number;
    title: string;
    description: string;
    additionalInfo: string;
}

export default function HomeContentView() {
    const navigate = useNavigate();
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [firstName, setFirstName] = useState<string | null>(null);
    const [fictions, setFictions] = useState<Fiction[]>([]);
    const [announcements, setAnnouncements] = useState<Announcement[]>([]);
    const [currentAnnouncementIndex, setCurrentAnnouncementIndex] = useState(0);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const theme = Cookies.get("theme");
        setIsDarkMode(theme === "dark");

        const fetchSessionInfo = async () => {
            if (await Session.doesSessionExist()) {
                try {
                    const response = await axios.get(getApiDomain() + "/sessioninfo");
                    const first_name = response.data.metadata?.first_name;
                    if (first_name) {
                        setFirstName(first_name);
                    }
                } catch (error) {
                    console.error("Error fetching session info:", error);
                }
            }
        };

        const fetchFictions = async () => {
            try {
                const response = await axios.get(`${getApiDomain()}/api/cashable/fictions/allfictions/textlist`);
                const fetchedFictions = response.data.fictions.map((fiction: any) => ({
                    id: fiction.id,
                    cover: `${getApiDomain()}/api/fictions/imagefile/${fiction.id}.jpg`,
                    title: fiction.title,
                    chapters: fiction.chapter_count,
                    comments: 0,
                    placeholder1: 0,
                    placeholder2: 0
                }));
                setFictions(fetchedFictions);
            } catch (error) {
                console.error('Error fetching fictions:', error);
            }
        };

        const fetchAnnouncements = async () => {
            try {
                const dummyAnnouncements = [
                    {
                        id: 1,
                        title: 'Welcome to Snowing Pine Stories!',
                        description: 'We are aiming to supplement the existing Patreon experience by providing creators a new and better way to seamlessly provide advance chapter content.',
                        additionalInfo: '' // Add this line
                    },
                    {
                        id: 2,
                        title: 'This is the Announcement Area',
                        description: 'Welcome Beta Tester! In this area you will find the latest announcements and updates. Make sure to check it often!',
                        additionalInfo: '' // Add this line
                    }
                ];
                setAnnouncements(dummyAnnouncements);
            } catch (error) {
                console.error('Error fetching announcements:', error);
            }
        };

        fetchSessionInfo();
        fetchFictions();
        fetchAnnouncements();
    }, []);

    useEffect(() => {
        const startInterval = () => {
            intervalRef.current = setInterval(() => {
                setCurrentAnnouncementIndex((prevIndex) => (prevIndex + 1) % announcements.length);
            }, 8000);
        };

        startInterval();

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [announcements]);

    function openLink(url: string) {
        window.open(url, "_blank");
    }

    async function toggleTheme() {
        const newTheme = isDarkMode ? "light" : "dark";
        setIsDarkMode(!isDarkMode);
        Cookies.set("theme", newTheme, { expires: 365, sameSite: "None", secure: true });
        await updateMetadata({ preferences: { theme: newTheme } });
    }

    const links: ILink[] = [
        // {
        //     name: "Settings",
        //     onClick: profileClicked,
        //     icon: isDarkMode ? SettingsWhiteFill : SettingsBlackFill,
        // },
    ];

    const currentStyles = isDarkMode ? darkStyles : styles;
    const localCurrentStyles = isDarkMode ? darkHomeStyles : lightHomeStyles;

    const handleAnnouncementChange = (index: number) => {
        setCurrentAnnouncementIndex(index);
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
            setCurrentAnnouncementIndex((prevIndex) => (prevIndex + 1) % announcements.length);
        }, 8000);
    };

    return (
        <>
            <Helmet>
                <title>Home | Snowing Pine Stories</title>
                <meta name="description" content="Welcome to Snowing Pine Stories! Read advance chapters in style from your favorite Patreon creators." />
                <meta property="og:title" content="Snowing Pine Stories" />
                <meta property="og:description" content="Read advance chapters in style from your favorite Patreon creators." />
                <meta property="og:image" content={bg3} />
                <meta property="og:url" content={window.location.href} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Snowing Pine Stories" />
                <meta name="twitter:description" content="Read advance chapters in style from your favorite Patreon creators." />
                <meta name="twitter:image" content={bg3} />
            </Helmet>
            <div className={`${localCurrentStyles.mainContainer} home-content-view`}>
                <div className={localCurrentStyles.innerContent}>
                    <div className={localCurrentStyles.announcementBanner}>
                        {announcements.length > 0 ? (
                            <>
                                <div key={announcements[currentAnnouncementIndex].id}>
                                    <img 
                                        src={bg3} 
                                        alt="Announcement Banner" 
                                    />
                                    <div className={localCurrentStyles.announcementText}>
                                        <h2>{announcements[currentAnnouncementIndex].title}</h2>
                                        <p>{announcements[currentAnnouncementIndex].description}</p>
                                        <p>{announcements[currentAnnouncementIndex].additionalInfo}</p>
                                    </div>
                                </div>
                                <div className={localCurrentStyles.pagination}>
                                    {announcements.map((_, index) => (
                                        <span
                                            key={index}
                                            className={`${localCurrentStyles.paginationDot} ${index === currentAnnouncementIndex ? localCurrentStyles.active : ''}`}
                                            onClick={() => handleAnnouncementChange(index)}
                                        ></span>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <p>No announcements available</p>
                        )}
                    </div>
                    <div className={localCurrentStyles.fictionsBarContainer}>
                        <div className={localCurrentStyles.fictionsBarTitle}>
                            FICTIONS
                        </div>
                        <div className={localCurrentStyles.fictionsBar}>
                            {fictions.length > 0 ? (
                                fictions.map(fiction => (
                                    <div 
                                        key={fiction.id} 
                                        className={localCurrentStyles.fictionCard} 
                                        onClick={() => navigate(`/fiction/${fiction.id}`)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <img 
                                            src={fiction.cover} 
                                            alt={fiction.title} 
                                            className={localCurrentStyles.fictionCover} 
                                        />
                                        <div className={localCurrentStyles.fictionDetails}>
                                            <h3 className={localCurrentStyles.fictionTitle}>
                                                {fiction.title}
                                            </h3>
                                            <button className={localCurrentStyles.readNowButton}>
                                                Read Now
                                            </button>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <MiniLoading />
                            )}
                        </div>
                    </div>
                </div>
                {/* <div className={currentStyles.bottomLinksContainer}>
                    {links.map((link) => (
                        <div className={currentStyles.button} key={link.name} role={"button"} onClick={link.onClick}>
                            <img className={currentStyles.linkIcon} src={link.icon} alt={link.name} />
                            <div>
                                {link.name}
                            </div>
                        </div>
                    ))}
                    <button className={currentStyles.button} onClick={toggleTheme}>
                        <img src={isDarkMode ? LightModeWhiteFill : DarkModeBlack} alt="Toggle Theme" />
                        <span>{isDarkMode ? "Light Mode" : "Dark Mode"}</span>
                    </button>
                </div> */}
            </div>
        </>
    );
}