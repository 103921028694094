import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/session";
import { recipeDetails } from "../config";
import { BlogsIcon, GuideIcon, SignOutIcon, LightModeWhiteFill, DarkModeBlack, BlogsIconWhite, SignOutIconWhite, HomeIconBlack, HomeIconWhite } from "../assets/images";
import axios from "axios";
import { getApiDomain } from "../config";
import styles from "../styles/GlobalContentViewStyles.module.css";
import darkStyles from "../styles/DarkGlobalContentViewStyles.module.css";
import Cookies from "js-cookie";
import { updateMetadata } from "../utils/updatemetadata";

interface ILink {
    name: string;
    onClick: () => void;
    icon: string;
}

export default function ContentView() {
    const navigate = useNavigate();
    const [apiData, setApiData] = useState(null);
    const [highestSystemNumber, setHighestSystemNumber] = useState(0);
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = Cookies.get("theme");
        return savedTheme === "dark";
    });
    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        Cookies.set("theme", isDarkMode ? "dark" : "light", { expires: 365, sameSite: "None", secure: true });
    }, [isDarkMode]);

    async function onClicked() {
        navigate("/");
    }

    async function onTextEditorClicked() {
        navigate("/text-editor");
    }

    async function onProfileClicked() {
        navigate("/profile");
    }

    function openLink(url: string) {
        window.open(url, "_blank");
    }

    async function logoutClicked() {
        await signOut();
        navigate("/");
    }

    async function onAuthorDashboardClicked() {
        navigate("/author-dashboard");
    }

    async function fetchApiData() {
        try {
            let response = await axios.get(getApiDomain() + "/api/cashable/testinglink");
            setApiData(response.data);
        } catch (error) {
            console.error("Error fetching API data:", error);
        }
    }

    async function fetchSessionInfo() {
        try {
            let response = await axios.get(getApiDomain() + "/sessioninfo");
            const permissions = response.data.accessTokenPayload["st-perm"].v;
            const systemPermissions = permissions.filter((perm: string) => perm.startsWith("system-"));
            const systemNumbers = systemPermissions.map((perm: string) => parseInt(perm.split("-")[1]));
            const highestNumber = Math.max(...systemNumbers, 0);
            setHighestSystemNumber(highestNumber);
        } catch (error) {
            console.error("Error fetching session info:", error);
        }
    }

    useEffect(() => {
        fetchApiData();
        fetchSessionInfo();
    }, []);

    async function requestNotificationPermission() {
        try {
            console.log("Requesting notification permission");
            const permission = await Notification.requestPermission();
            if (permission !== 'granted') {
                console.error("Notification permission not granted");
                return;
            }
            console.log("Notification permission granted");

            const registration = await navigator.serviceWorker.ready;
            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: 'BIINfx4wWl_arxT3Q2_Q1aOQY-9eezGXyyoFdQPG937DEUImLK4MvCITXdvjTXI0ylDVw2FAjBtc-bpp5kQXZGM'
            });

            console.log('Push subscription:', subscription);

            await axios.post(getApiDomain() + "/api/push_subscription_data", subscription);

            console.log("Subscription data sent to server");
        } catch (error) {
            console.error("Error requesting notification permission or subscribing:", error);
        }
    }

    const sendPushMessage = async () => {
        try {
            const response = await axios.post(getApiDomain() + "/api/send-push-message", {
                title: "Custom Notification",
                body: "This is a custom notification body.",
                icon: "snowingpine_logo.png",
                url: "/profile",
                fiction_id: 1,
            });

            console.log("Push message sent:", response.data);
        } catch (error) {
            console.error("Error sending push message:", error);
        }
    };

    const sendUserPushMessage = async () => {
        try {
            const fictionId = 1;
            const chapterId = 39;
            const formattedTitle = 'chapter-229-flowers';
            const formattedFictionTitle = 'demon-world-boba-shop-a-cozy-fantasy-novel';

            const title = `Chapter 229: Flowers | Demon World Boba Shop: A Cozy Fantasy Novel | Snowing Pine Stories`;
            const rawbody = 'Out on the town was a big deal when Arthur was in the largest, most high-level-class filled city in the Demon World. That was especially true when Coldbrook was flush with cash, and had strapped the expo party down with more money than he could reasonably spend over the course of a couple of weeks.'
            const notificationBody = `${rawbody.substring(0, 140)}...`;
            const icon = `https://api.snowingpine.com/api/fictions/imagefile/${fictionId}.jpg`;
            const url = `/fiction/${fictionId}/${formattedFictionTitle}/chapter/${chapterId}/${formattedTitle}`;

            const response = await axios.post(getApiDomain() + "/api/haha-very-funny-No", {
                title,
                body: notificationBody,
                icon,
                url,
                fiction_id: 1,
            });

            console.log("Push message sent to user:", response.data);
        } catch (error) {
            console.error("Error sending push message to user:", error);
        }
    };

    const toggleTheme = async () => {
        const newTheme = isDarkMode ? "light" : "dark";
        setIsDarkMode(!isDarkMode);
        const updates = {
            preferences: {
                theme: newTheme
            }
        };
        await updateMetadata(updates);
        Cookies.set("theme", newTheme, { 
            expires: 365,
            sameSite: 'None',
            secure: true
        });
        window.location.reload();
    };

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const subfolder = prompt("Please enter the subfolder name:");
        if (!subfolder) {
            console.error("No subfolder specified.");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('subfolder', subfolder);

        try {
            const response = await axios.post(getApiDomain() + "/api/upload", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log("File uploaded successfully:", response.data);
            // You can add further actions here, such as showing a success message
        } catch (error) {
            console.error("Error uploading file:", error);
            // You can add error handling here, such as showing an error message
        }
    };

    const openFileDialog = () => {
        fileInputRef.current?.click();
    };

    async function onTrueHomeClicked() {
        navigate("/home");
    }

    const links: ILink[] = [
        {
            name: "Home",
            onClick: onClicked,
            icon: isDarkMode ? HomeIconWhite : HomeIconBlack,
        },
        {
            name: "Sign Out",
            onClick: logoutClicked,
            icon: isDarkMode ? SignOutIconWhite : SignOutIcon,
        },
        {
            name: "TextEditor",
            onClick: onTextEditorClicked,
            icon: isDarkMode ? BlogsIconWhite : BlogsIcon,
        },
        ...(highestSystemNumber >= 2 ? [
            {
                name: "Profile",
                onClick: onProfileClicked,
                icon: GuideIcon,
            },
            {
                name: "True Home",
                onClick: onTrueHomeClicked,
                icon: GuideIcon,
            },
            {
                name: "Author Dashboard",
                onClick: onAuthorDashboardClicked,
                icon: GuideIcon,
            },
        ] : []),
    ];

    const currentStyles = isDarkMode ? darkStyles : styles;

    return (
        <>
            <div className={currentStyles.mainContainer}>
                <div className={currentStyles.innerContent}>
                    <h1>Settings</h1>
                    <p>API DATA:</p>
                    {apiData && <pre>{JSON.stringify(apiData, null, 2)}</pre>}
                    <h2>Notification Settings</h2>
                    <div className={currentStyles.buttonContainer}>
                        <button className={currentStyles.button} onClick={requestNotificationPermission}>
                            <span>Request Notification Permission</span>
                        </button>
                        {highestSystemNumber >= 4 && (
                            <button className={currentStyles.button} onClick={sendPushMessage}>
                                <span>Send Global Push Notification</span>
                            </button>
                        )}
                        <button className={currentStyles.button} onClick={sendUserPushMessage}>
                            <span>Send User Push Notification</span>
                        </button>
                        {highestSystemNumber >= 2 && (
                            <>
                                <button className={currentStyles.button} onClick={openFileDialog}>
                                    <span>Upload File</span>
                                </button>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileUpload}
                                />
                            </>
                        )}
                    </div>
                </div>
                <div className={currentStyles.bottomLinksContainer}>
                    {links.map((link) => (
                        <div className={currentStyles.button} key={link.name} role={"button"} onClick={link.onClick}>
                            <img className={currentStyles.linkIcon} src={link.icon} alt={link.name} />
                            <div>
                                {link.name}
                            </div>
                        </div>
                    ))}
                    <button className={currentStyles.button} onClick={toggleTheme}>
                        <img src={isDarkMode ? LightModeWhiteFill : DarkModeBlack} alt="Toggle Theme" />
                        <span>{isDarkMode ? "Light Mode" : "Dark Mode"}</span>
                    </button>
                </div>
            </div>
        </>
    );
}