import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './DashboardInnerNavBar.module.css';
import darkStyles from './DarkDashboardInnerNavBar.module.css';
import { MailFillBlack, ProfileFillBlack, NotifBellFilledBlack, ArrowDownBlack, MailFillWhite, NotifBellFilledWhite, ProfileFillWhite } from '../../assets/images'; // Assume these icons are available
import Cookies from 'js-cookie';
import axios from 'axios';
import Session from "supertokens-auth-react/recipe/session";
import { getApiDomain } from '../../config';

interface DashboardInnerNavBarProps {
    location?: string;
    path?: string;
    subpath?: string;
}

// Fake API call to get notification and mail counts
const fetchNotificationCounts = async () => {
    // This is a dummy implementation. Replace with actual API call.
    return {
        notifications: 0,
        mails: 0
    };
};

const DashboardInnerNavBar: React.FC<DashboardInnerNavBarProps> = ({ location = "Author Dashboard", path = "Home", subpath }) => {
    const navigate = useNavigate();
    const [notifCount, setNotifCount] = useState(0);
    const [mailCount, setMailCount] = useState(0);
    const [username, setUsername] = useState("");
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = Cookies.get("theme");
        return savedTheme === "dark";
    });

    useEffect(() => {
        const fetchSessionInfo = async () => {
            if (await Session.doesSessionExist()) {
                try {
                    const response = await axios.get(getApiDomain() + "/sessioninfo");
                    const username = response.data.metadata?.username;
                    if (username) {
                        setUsername(username)
                    }
                } catch (error) {
                    console.error("Error fetching session info:", error);
                }
            }
        };


        const getCounts = async () => {
            const counts = await fetchNotificationCounts();
            setNotifCount(counts.notifications);
            setMailCount(counts.mails);
        };

        fetchSessionInfo();
        getCounts();
    }, []);

    const currentStyles = isDarkMode ? darkStyles : styles;
    const NotifBellIcon = isDarkMode ? NotifBellFilledWhite : NotifBellFilledBlack;
    const MailIcon = isDarkMode ? MailFillWhite : MailFillBlack;
    const ProfileIcon = isDarkMode ? ProfileFillWhite : ProfileFillBlack;

    return (
        <div className={currentStyles.topNavBar}>
            <div className={currentStyles.topRow}>
                <div className={currentStyles.left}>
                    <button className={currentStyles.navButton} onClick={() => navigate('/')}>Read</button>
                    <button className={currentStyles.navButton} onClick={() => navigate('/author-dashboard')}>Write</button>
                    <div className={currentStyles.dropdown}>
                        <button className={currentStyles.navButton}>Support <img src={ArrowDownBlack} alt="Arrow Down" /></button>
                        <div className={currentStyles.dropdownContent}>
                            <button className={currentStyles.dropdownItem}>Support Tickets</button>
                            <button className={currentStyles.dropdownItem}>Knowledge Center</button>
                            <button className={currentStyles.dropdownItem}>Suggestions</button>
                        </div>
                    </div>
                </div>
                <div className={currentStyles.right}>
                    <div className={currentStyles.iconWithCounter}>
                        <img src={NotifBellIcon} alt="Notifications" />
                        {notifCount > 0 && <span className={currentStyles.counter}>{notifCount}</span>}
                    </div>
                    <div className={currentStyles.iconWithCounter}>
                        <img src={MailIcon} alt="Mail" />
                        {mailCount > 0 && <span className={currentStyles.counter}>{mailCount}</span>}
                    </div>
                    <div className={currentStyles.userInfo}>
                        <span>{username}</span>
                        <img src={ProfileIcon} alt="Profile" />
                    </div>
                </div>
            </div>
            <div className={currentStyles.bottomRow}>
                <span>{location}</span>
                <span className={currentStyles.subcategory}>
                    {subpath ? `${path} → ${subpath}` : path}
                </span>
            </div>
        </div>
    );
};

export default DashboardInnerNavBar;