'use client'

import DOMPurify from 'dompurify'
import styles from './AuthorsNote.module.css'
import darkStyles from './AuthorsNoteDark.module.css'

export default function AuthorsNote({ note, theme, author }: { note: string, theme: string, author: string }) {
  const sanitizedNote = DOMPurify.sanitize(note)

  const currentStyles = theme === 'dark' ? darkStyles : styles;
  
  author = author.toUpperCase();

  if (sanitizedNote && sanitizedNote.length > 0) {
    return (
      <div className={currentStyles.card}>
        <div className={currentStyles.cardContent}>
          <div className={currentStyles.header}>
            <h2 className={currentStyles.title}>A NOTE FROM {author}</h2>
          </div>
          <div className={currentStyles.text} dangerouslySetInnerHTML={{ __html: sanitizedNote }} />
        </div>
      </div>
    )
  } else {
    return null;
  }
}