import React, { useRef, useState, useEffect, useCallback } from 'react';
import styles from './TapMenu.module.css';
import stylesDark from './TapMenuDark.module.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

interface TapMenuProps {
  setDrawerOpen: (open: boolean) => void;
  changeTheme: (theme: string) => void;
  setFontSize: (size: number) => void;
  fontSize: number;
  fictionID: string;
  isDarkMode: boolean;
}

const TapMenu: React.FC<TapMenuProps> = ({
  setDrawerOpen,
  changeTheme,
  setFontSize,
  fontSize,
  fictionID,
  isDarkMode,
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const currentStyles = isDarkMode ? stylesDark : styles;

  useEffect(() => {
    const savedFontSize = Cookies.get('reader_fontSize');
    if (savedFontSize) {
      setFontSize(parseInt(savedFontSize, 10));
    }
  }, [setFontSize]);

  const fictionNavigation = (ID: string) => {
    if (ID === 'null') {
      navigate('/');
    } else {
      navigate(`/fiction/${ID}`);
    }
  };

  const handleFontSizeChange = useCallback((newSize: number) => {
    setFontSize(newSize);
    console.log(newSize);
    Cookies.set('reader_fontSize', newSize.toString(), { expires: 365 });
  }, [setFontSize]);

  const handleSliderEventInternal = useCallback((e: React.MouseEvent | React.TouchEvent) => {
    const slider = sliderRef.current;
    if (!slider) return;
    const rect = slider.getBoundingClientRect();
    let offsetX = 0; // Initialize with a default value

    if (e.type === 'mousedown' || e.type === 'mousemove') {
      offsetX = (e as React.MouseEvent).clientX - rect.left;
    } else if (e.type === 'touchstart' || e.type === 'touchmove') {
      offsetX = (e as React.TouchEvent).touches[0].clientX - rect.left;
    }

    const newFontSize = Math.round((offsetX / rect.width) * 12 + 12);
    handleFontSizeChange(newFontSize);
  }, [handleFontSizeChange]);

  useEffect(() => {
    const slider = sliderRef.current;

    if (!slider) return;

    const handleMouseMove = (e: MouseEvent) => {
      if (e.buttons === 1) handleSliderEventInternal(e as unknown as React.MouseEvent);
    };

    const handleTouchMove = (e: TouchEvent) => handleSliderEventInternal(e as unknown as React.TouchEvent);

    slider.addEventListener('mousemove', handleMouseMove);
    slider.addEventListener('touchmove', handleTouchMove);

    return () => {
      if (!slider) return;
      slider.removeEventListener('mousemove', handleMouseMove);
      slider.removeEventListener('touchmove', handleTouchMove);
    };
  }, [sliderRef, handleSliderEventInternal]);

  return (
    <div className={currentStyles.drawerOverlay} onClick={() => setDrawerOpen(false)}>
      <div
        className={currentStyles.drawerContentBottom}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={currentStyles.themeCircles}>
          <div
            className={`${currentStyles.themeCircle} ${currentStyles.lightTheme}`}
            onClick={() => changeTheme('light')}
          />
          <div
            className={`${currentStyles.themeCircle} ${currentStyles.paleBeigeTheme}`}
            onClick={() => changeTheme('pale-beige')}
          />
          <div
            className={`${currentStyles.themeCircle} ${currentStyles.parchmentTheme}`}
            onClick={() => changeTheme('parchment')}
          />
          <div
            className={`${currentStyles.themeCircle} ${currentStyles.darkTheme}`}
            onClick={() => changeTheme('dark')}
          />
        </div>
        <div
          ref={sliderRef}
          className={currentStyles.customSlider}
          onMouseDown={handleSliderEventInternal}
          onTouchStart={handleSliderEventInternal}
          onMouseMove={(e) => e.buttons === 1 && handleSliderEventInternal(e)}
          onTouchMove={handleSliderEventInternal}
        >
          <div
            className={currentStyles.customSliderFill}
            style={{ width: `${((fontSize - 12) / 12) * 100}%` }}
          />
          <span className={currentStyles.sliderLabel}>Font Size: {fontSize}px</span>
        </div>
        <div className={currentStyles.chapterNav}>
          <button
            className={currentStyles.navButton}
            onClick={(e) => {
              e.stopPropagation();
              fictionNavigation(fictionID);
            }}
          >
            Fiction Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default TapMenu;
