import { useState, useEffect } from 'react'
import { Button } from "../components/button"
import { Card } from "../components/card"
import { Badge } from "../components/badge"
import { BookOpen, Heart, Bookmark, Edit, AlertTriangle } from 'lucide-react'
import { PatreonLogo, PatreonWordmark } from "../assets/images";
import styles from './FictionInfo.module.css'
import stylesDark from './FictionInfoDark.module.css'
import { useNavigate } from 'react-router-dom'
import DOMPurify from 'dompurify'

interface FictionInfoProps {
  title: string
  author: string
  coverImage: string
  description: string
  tags?: string[]
  earliestChapter: number
  fictionId: string
  isFollowed: boolean
  isFavorite: boolean
  isAuthor: boolean
  isAlreadyReading: boolean
  currentChapter: number
  patreon: string
  isDarkMode: boolean
  toggleFollow: () => void
  toggleFavorite: () => void
  followFavoriteLoading: boolean
}

export default function FictionInfo({ 
  title, 
  author, 
  coverImage, 
  description, 
  tags = [], 
  earliestChapter,
  fictionId,
  isAuthor = false,
  isFollowed = false,
  isFavorite = false,
  isAlreadyReading = false,
  currentChapter,
  patreon,
  isDarkMode,
  toggleFollow,
  toggleFavorite,
  followFavoriteLoading
}: FictionInfoProps) {
  const [showFullDescription, setShowFullDescription] = useState(false)
  const navigate = useNavigate()

  const sanitizedDescription = DOMPurify.sanitize(description);
  
  const appliedStyles = isDarkMode ? stylesDark : styles

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Card className={appliedStyles.card}>
      <div className={appliedStyles.content}>
        <div className={appliedStyles.imageAndTitleContainer}>
          <div className={appliedStyles.imageContainer}>
            <img src={coverImage} alt={`Cover of ${title}`} className={appliedStyles.coverImage} />
          </div>
          <div className={appliedStyles.infoContainer}>
            <h2 className={appliedStyles.title}>{title}</h2>
            <a 
              href={`/author/${author.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-')}`}
              onClick={(e) => {
                e.preventDefault();
                const sterilizedAuthor = author.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-');
                navigate(`/author/${sterilizedAuthor}`);
              }}
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              by {author}
            </a>
            {tags.length > 0 && (
              <div className={appliedStyles.tagContainer}>
                {tags.map((tag, index) => (
                  <Badge key={index} variant="secondary" className={appliedStyles.tag}>{tag}</Badge>
                ))}
              </div>
            )}
            {!isMobile && (
              <div className={appliedStyles.descriptionContainer}>
                <p 
                  className={showFullDescription ? '' : appliedStyles.truncate} 
                  dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                ></p>
                <button 
                  onClick={() => setShowFullDescription(!showFullDescription)}
                  className={appliedStyles.showMoreButton}
                >
                  {showFullDescription ? 'Show Less' : 'Show More'}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className={appliedStyles.buttonContainer}>
            <a 
              href={`/fiction/${fictionId}/${title.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-').substring(0, 100)}/chapter/${isAlreadyReading ? currentChapter : earliestChapter}`}
              onClick={(e) => {
                e.preventDefault();
                const sterilizedTitle = title.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-').substring(0, 100);
                const chapterToRead = isAlreadyReading ? currentChapter : earliestChapter;
                navigate(`/fiction/${fictionId}/${sterilizedTitle}/chapter/${chapterToRead}`);
              }}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Button 
                variant="dark-default"
                className={appliedStyles.button}
              >
                <BookOpen className={appliedStyles.icon} />
                {isAlreadyReading ? 'Continue Reading' : 'Start Reading'}
              </Button>
            </a>
            <Button 
              variant="outlinewhite" 
              className={appliedStyles.button}
              onClick={toggleFollow}
              disabled={followFavoriteLoading}
            >
              <Bookmark className={appliedStyles.icon} />
              {isFollowed ? 'Unfollow' : 'Follow'}
            </Button>
            <Button 
              variant="outlinewhite" 
              className={appliedStyles.button}
              onClick={toggleFavorite}
              disabled={followFavoriteLoading}
            >
              <Heart className={appliedStyles.icon} />
              {isFavorite ? 'Unfavorite' : 'Favorite'}
            </Button>
            {isAuthor ? (
              <a 
                href={`/author-dashboard/dashboard/${fictionId}`} 
                style={{ textDecoration: 'none', color: 'inherit' }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/author-dashboard/dashboard/${fictionId}`);
                }}
              >
                <Button 
                  variant="secondary" 
                  className={appliedStyles.button}
                >
                  <Edit className={appliedStyles.icon} />
                  Edit
                </Button>
              </a>
            ) : (
              <a 
                href={`https://www.patreon.com/${patreon}`} 
                style={{ textDecoration: 'none', color: 'inherit' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button 
                  variant="secondary" 
                  className={appliedStyles.button}
                >
                  <img src={PatreonLogo} alt="Patreon Logo" className={appliedStyles.patreonLogo} style={{ maxWidth: '15px', maxHeight: '15px' }} />
                  <img src={PatreonWordmark} alt="Patreon Wordmark" className={appliedStyles.patreonWordmark} style={{ maxWidth: '100px', maxHeight: '25px' }} />
                </Button>
              </a>
            )}
        </div>
        {isMobile && (
          <div className={appliedStyles.descriptionContainer}>
            <p 
              className={showFullDescription ? '' : appliedStyles.truncate} 
              dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
            ></p>
            <button 
              onClick={() => setShowFullDescription(!showFullDescription)}
              className={appliedStyles.showMoreButton}
            >
              {showFullDescription ? 'Show Less' : 'Show More'}
            </button>
          </div>
        )}
      </div>
    </Card>
  )
}