import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { SettingsBlackFill, SettingsWhiteFill, LightModeWhiteFill, DarkModeBlack, BlogsIcon, BlogsIconWhite, bg3 } from "../assets/images";
import styles from "../styles/GlobalContentViewStyles.module.css";
import darkStyles from "../styles/DarkGlobalContentViewStyles.module.css";
import Cookies from "js-cookie";
import { updateMetadata } from "../utils/updatemetadata";
import axios from "axios";
import { getApiDomain } from "../config";
import Session from "supertokens-auth-react/recipe/session";
import lightReaderStyles from "./styles/Light.module.css";
import darkReaderStyles from "./styles/Dark.module.css";
import parchmentReaderStyles from "./styles/Parchment.module.css";
import lightBeigeReaderStyles from "./styles/LightBeige.module.css";
import MiniLoading from "../components/MiniLoading";
import FictionInfo from "./FictionInfo";
import MobileFictionInfo from "./MobileFictionInfo";
import TapMenu from "../components/TapMenu";
import DOMPurify from 'dompurify'
import ChapterNav from "./ChapterNav";
import AuthorsNote from './AuthorsNote'

export default function HomeContentView() {
    const navigate = useNavigate();
    const { fictionid, chapterid } = useParams();
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [firstName, setFirstName] = useState<string | null>(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [fontSize, setFontSize] = useState(() => {
        const savedFontSize = Cookies.get("reader_fontSize");
        console.log('savedFontSize', savedFontSize);
        return savedFontSize ? parseInt(savedFontSize, 10) : 16;
    });
    const [chapterLoading, setChapterLoading] = useState(true);
    const [chapterData, setChapterData] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);
    const [userId, setUserId] = useState<string | null>(null);
    const [sanitizedContent, setSanitizedContent] = useState<string | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [readerTheme, setReaderTheme] = useState<string | null>(null);
    const [showBacktrackQuestion, setShowBacktrackQuestion] = useState(false);
    const [userLatestChapter, setUserLatestChapter] = useState<number | null>(null);
    const [nextChapterInfo, setNextChapterInfo] = useState({
        chapterID: null,
        chapterTitle: null,
        chapterLocked: null,
        chapterAccessible: null,
        chapterExists: null
    });

    const [prevChapterInfo, setPrevChapterInfo] = useState({
        chapterID: null,
        chapterTitle: null,
        chapterExists: null
    });

    const sampleNote = "In this chapter, I wanted to explore the deeper motivations behind the protagonist's actions. The scene by the river is particularly significant as it represents a turning point in their journey. I drew inspiration from my own experiences of self-discovery and the works of Joseph Campbell on the hero's journey. I hope this chapter resonates with readers and provides insight into the character's growth."

    useEffect(() => {
        const theme = Cookies.get("reader_theme") || Cookies.get("theme");
        setReaderTheme(theme || 'light');
        setIsDarkMode(theme === "dark");
        

        const fetchSessionInfo = async () => {
            if (await Session.doesSessionExist()) {
                try {
                    const response = await axios.get(getApiDomain() + "/sessioninfo");
                    const username = response.data.metadata?.username;
                    const readingProgress = response.data.metadata?.reading_progress;
                    const progress = readingProgress ? readingProgress[fictionid as keyof typeof readingProgress] : null;
                    if (progress) {
                        console.log(`Reading progress for fiction ID ${fictionid}: ${progress}`);
                        setUserLatestChapter(parseInt(progress, 10))
                        if (progress !== chapterid) {
                            const readingProgressUpdate = { reading_progress: { [fictionid as string]: chapterid as string } };
                            console.log(readingProgressUpdate);
                            await updateMetadata(readingProgressUpdate);
                        }
                    } else {
                        if (chapterid) {
                            const readingProgressUpdate = { reading_progress: { [fictionid as string]: chapterid } };
                            console.log(readingProgressUpdate);
                            setUserLatestChapter(parseInt(chapterid, 10));
                            await updateMetadata(readingProgressUpdate);
                        } else {
                            console.error("chapterid is undefined");
                        }
                    }

                    if (chapterid) {
                        let readChapters = response.data.metadata?.read_chapters;
                        console.log('readChapters', readChapters);
                        const readChapterIDs = readChapters ? Object.values(readChapters) : [];

                        const isRead = readChapterIDs.includes(chapterid.toString());
                        
                        if (!isRead) {
                            readChapterIDs.push(chapterid.toString());
                            console.log('readChapters', readChapterIDs);
                            const updatedMetadata = { read_chapters: readChapterIDs };
                            await updateMetadata(updatedMetadata);
                        }
                    }

                    const user_id = response.data.userId;
                    if (username) {
                        setFirstName(username);
                    }
                    if (user_id) {
                        setUserId(user_id);
                    }
                } catch (error) {
                    console.error("Error fetching session info:", error);
                }
            }
        };

        const fetchChapter = async () => {
            try {
                const endpoint = await Session.doesSessionExist() ? '/api/chapters/pull' : '/api/chapters/publicpull';
                const response = await axios.get(`${getApiDomain()}${endpoint}`, { params: { chapterID: chapterid, fictionID: fictionid } });
                setChapterData(response.data);
                setNextChapterInfo({
                    chapterID: response.data.nextChapterID,
                    chapterTitle: response.data.nextChapterTitle,
                    chapterLocked: response.data.nextChapterLocked,
                    chapterAccessible: response.data.nextChapterAccessible,
                    chapterExists: response.data.nextChapterExists
                });
                setPrevChapterInfo({
                    chapterID: response.data.prevChapterID,
                    chapterTitle: response.data.prevChapterTitle,
                    chapterExists: response.data.prevChapterExists
                });
                setSanitizedContent(DOMPurify.sanitize(response.data.chapter_content));
                setError(null);
            } catch (error) {
                console.error('Error fetching chapter info:', error);
                setError('Error fetching chapter info. Please try again later.');
            } finally {
                setChapterLoading(false);
            }
        };

        fetchSessionInfo();
        fetchChapter();

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [fictionid, chapterid]);

    useEffect(() => {
        if (chapterData) {
            const formattedFictionTitle = formatTitle(chapterData.fiction_details.title);
            const formattedChapterTitle = formatTitle(chapterData.title);

            navigate(`/fiction/${fictionid}/${formattedFictionTitle}/chapter/${chapterid}/${formattedChapterTitle}`, { replace: true });
        }
    }, [chapterData, fictionid, chapterid, navigate]);

    const formatTitle = (title: string) => {
        return title.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-').substring(0, 100);
    };

    async function changeTheme(theme: string) {
        setIsDarkMode(theme === "dark");
        setReaderTheme(theme);
        Cookies.set("reader_theme", theme, { expires: 365, sameSite: "None", secure: true });
        await updateMetadata({ preferences: { reader_theme: theme } });
    }

    const currentStyles = isDarkMode ? darkStyles : styles;
    let localCurrentStyles = lightReaderStyles;
    switch (readerTheme) {
        case 'parchment':
            localCurrentStyles = parchmentReaderStyles;
            break;
        case 'pale-beige':
            localCurrentStyles = lightBeigeReaderStyles;
            break;
        case 'light':
            localCurrentStyles = lightReaderStyles;
            break;
        case 'dark':
            localCurrentStyles = darkReaderStyles;
            break;
        default:
            localCurrentStyles = lightReaderStyles;
    }


    const coverImageUrl = `${getApiDomain()}/api/fictions/imagefile/${fictionid}.jpg`;

    useEffect(() => {
        let mouseDownTime: number;

        const handleMouseDown = (event: MouseEvent) => {
            if (event.target instanceof HTMLElement && event.target.closest(`.${localCurrentStyles.chapterContent}`)) {
                setIsDragging(false);
                mouseDownTime = Date.now();
            }
        };

        const handleMouseMove = (event: MouseEvent) => {
            if (event.target instanceof HTMLElement && event.target.closest(`.${localCurrentStyles.chapterContent}`)) {
                setIsDragging(true);
            }
        };

        const handleMouseUp = (event: MouseEvent) => {
            if (event.target instanceof HTMLElement && event.target.closest(`.${localCurrentStyles.chapterContent}`)) {
                const mouseUpTime = Date.now();
                if (!isDragging && (mouseUpTime - mouseDownTime) <= 1500) {
                    setDrawerOpen(true);
                }
            }
        };

        if (isMobile) {
            window.addEventListener("mousedown", handleMouseDown);
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp);
        }

        return () => {
            if (isMobile) {
                window.removeEventListener("mousedown", handleMouseDown);
                window.removeEventListener("mousemove", handleMouseMove);
                window.removeEventListener("mouseup", handleMouseUp);
            }
        };
    }, [isDragging, isMobile, localCurrentStyles.chapterContent]);

    useEffect(() => {
        const readChapterIDs = JSON.parse(Cookies.get('readChapterIDs') || '[]');
        if (!readChapterIDs.includes(chapterid)) {
            readChapterIDs.push(chapterid);
            Cookies.set('readChapterIDs', JSON.stringify(readChapterIDs), { expires: 365, sameSite: "None", secure: true });
        }
    }, [chapterid]);

    return (
        <>
            {chapterData && (
                    <Helmet>
                        <title>{chapterData.title} - {chapterData.fiction_details.title} | Snowing Pine Stories</title>
                        <meta name="description" content={chapterData.chapter_content.replace(/<[^>]+>/g, '').substring(0, 200)} />
                        <meta property="og:title" content={chapterData.fiction_details.title} />
                        <meta property="og:description" content={chapterData.chapter_content.replace(/<[^>]+>/g, '').substring(0, 200)} />
                        <meta property="og:image" content={coverImageUrl || ''} />
                        <meta property="og:url" content={window.location.href} />
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:title" content={chapterData.fiction_details.title} />
                        <meta name="twitter:description" content={chapterData.chapter_content.replace(/<[^>]+>/g, '').substring(0, 200)} />
                        <meta name="twitter:image" content={coverImageUrl || ''} />
                        <meta name="author" content={chapterData.user.full_name} />
                    </Helmet>
            )}
            <div className={`${localCurrentStyles.mainContainer} home-content-view`}>
                <div className={localCurrentStyles.innerContent}>
                    {chapterData && (
                        isMobile ? (
                            <MobileFictionInfo
                                fictionId={fictionid || ''}
                                patreonUrl={`https://www.patreon.com/${chapterData.fiction_details.patreon}`}
                                isDarkMode={isDarkMode}
                                coverImage={coverImageUrl}
                                title={chapterData.fiction_details.title}
                                author={chapterData.user.full_name}
                                chapterTitle={chapterData.title}
                                author_id={chapterData.fiction_details.creator_id}
                                user_id={userId || '0'}
                                isAuthor={userId === chapterData.fiction_details.creator_id}
                                chapterLoading={chapterLoading}
                            />
                        ) : (
                            <FictionInfo
                                fictionId={fictionid || ''}
                                patreonUrl={`https://www.patreon.com/${chapterData.fiction_details.patreon}`}
                                isDarkMode={isDarkMode}
                                coverImage={coverImageUrl}
                                title={chapterData.fiction_details.title}
                                author={chapterData.user.full_name}
                                chapterTitle={chapterData.title}
                                author_id={chapterData.fiction_details.creator_id}
                                user_id={userId || '0'}
                                isAuthor={userId === chapterData.fiction_details.creator_id}
                                chapterLoading={chapterLoading}
                                setDrawerOpen={setDrawerOpen}
                            />
                        )
                    )}
                    {chapterLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                            <MiniLoading />
                        </div>
                    ) : (
                        <>
                            {chapterData ? (
                                <>
                                    <ChapterNav
                                        prevChapterInfo={prevChapterInfo}
                                        nextChapterInfo={nextChapterInfo}
                                        fictionid={fictionid || ''}
                                        fictionTitle={chapterData.fiction_details.title || ''}
                                        showFictionPageButton={false}
                                        isAuthor={userId === chapterData.fiction_details.creator_id}
                                        setChapterLoading={setChapterLoading}
                                    />
                                    <AuthorsNote note={chapterData.pre_chapter_note} theme={readerTheme || ''} author={chapterData.user.full_name} />
                                    <div className={localCurrentStyles.chapterContent} style={{ fontSize: `${fontSize}px` }}>
                                        <div 
                                        dangerouslySetInnerHTML={{ __html: sanitizedContent || '' }} 
                                        className={localCurrentStyles.chapterContentTable}
                                        />
                                    </div>
                                    <AuthorsNote note={chapterData.post_chapter_note} theme={readerTheme || ''} author={chapterData.user.full_name} />
                                    <ChapterNav
                                        prevChapterInfo={prevChapterInfo}
                                        nextChapterInfo={nextChapterInfo}
                                        fictionid={fictionid || ''}
                                        fictionTitle={chapterData.fiction_details.title || ''}
                                        showFictionPageButton={true}
                                        isAuthor={userId === chapterData.fiction_details.creator_id}
                                        setChapterLoading={setChapterLoading}
                                    />
                                </>
                            ) : (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                    <h1>Error 404: Chapter Not Found</h1>
                                </div>
                            )}
                        </>
                    )}
                </div>
                
                {drawerOpen && <TapMenu isDarkMode={isDarkMode} setDrawerOpen={setDrawerOpen} changeTheme={changeTheme} fontSize={fontSize} setFontSize={setFontSize} fictionID={fictionid || 'null'}/>}
            </div>
        </>
    );
}