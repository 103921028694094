import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/session";
import { GuideIcon, SignOutIcon, LightModeWhiteFill, DarkModeBlack, SignOutIconWhite, HomeIconBlack, HomeIconWhite } from "../assets/images";
import styles from "./AuthorDashboardContent.module.css";
import darkStyles from "./AuthorDarkDashboardContent.module.css";
import Cookies from "js-cookie";
import axios from "axios";
import { getApiDomain } from "../config";

type Fiction = {
    id: number;
    coverImage: string;
    title: string;
    synopsis: string;
    patreon: string;
    created_at: string;
    creator_id: string;
};

type ContentViewProps = {
    fictionId: string;
};

export default function ContentView({ fictionId }: ContentViewProps) {
    const navigate = useNavigate();
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = Cookies.get("theme");
        return savedTheme === "dark";
    });

    const [fiction, setFiction] = useState<Fiction | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [linkedPatreon, setLinkedPatreon] = useState(false);
    const [linkedPatreonData, setLinkedPatreonData] = useState(null);
    const [tierSettings, setTierSettings] = useState<any>({});
    const [isSavingTierSettings, setIsSavingTierSettings] = useState(false);
    const [tierSettingsMessage, setTierSettingsMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        Cookies.set("theme", isDarkMode ? "dark" : "light", { expires: 365, sameSite: "None", secure: true });
    }, [isDarkMode]);

    useEffect(() => {
        const fetchFiction = async () => {
            try {
                const response = await axios.get(`${getApiDomain()}/api/fictions/info`, { params: { id: fictionId } });
                const fetchedFiction = response.data;
                setFiction(fetchedFiction);
                if (response.data) {
                    const initialSettings = response.data.tiers_settings 
                        ? response.data.tiers_settings.reduce((acc: any, setting: any) => {
                            acc[setting.id] = { id: setting.id, title: setting.title, early_chapter_count: setting.early_chapter_count };
                            return acc;
                        }, {})
                        : ((linkedPatreonData as any)?.tiers_data || []).reduce((acc: any, tier: any) => {
                            acc[tier.id] = { id: tier.id, title: tier.attributes.title, early_chapter_count: 0 };
                            return acc;
                        }, {});
                    setTierSettings(initialSettings);
                }
                setError(null);
            } catch (error) {
                console.error('Error fetching fiction info:', error);
                setError('Error fetching fiction info. Please try again later.');
            }
        };

        fetchFiction();
    }, [fictionId, linkedPatreonData]);

    useEffect(() => {
        async function checkLinkedPatreon() {
            try {
                const response = await axios.get(`${getApiDomain()}/api/patreon/getpatreon`);
                if (response.data) {
                    setLinkedPatreon(true);
                    setLinkedPatreonData(response.data);
                }
            } catch (error) {
                console.error('Error checking linked Patreon:', error);
            } finally {
                setIsLoading(false);
            }
        }
        checkLinkedPatreon();
    }, []);

    const currentStyles = isDarkMode ? darkStyles : styles;
    const handleCoverImageLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const img = event.target as HTMLImageElement;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 300; // Reduced from 400
        canvas.height = 450; // Reduced from 600, maintaining aspect ratio

        const scaleX = 300 / img.naturalWidth;
        const scaleY = 450 / img.naturalHeight;
        const scale = Math.max(scaleX, scaleY);

        const newWidth = img.naturalWidth * scale;
        const newHeight = img.naturalHeight * scale;

        const x = (300 - newWidth) / 2;
        const y = (450 - newHeight) / 2;

        if (ctx) {
            ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight, x, y, newWidth, newHeight);
            img.src = canvas.toDataURL();
        }
    };

    const handleTierSettingChange = (tierId: string, value: number) => {
        setTierSettings((prevSettings: any) => ({
            ...prevSettings,
            [tierId]: {
                ...prevSettings[tierId],
                early_chapter_count: value
            }
        }));
    };

    const saveTierSettings = async () => {
        setIsSavingTierSettings(true);
        setTierSettingsMessage(null);
        try {
            const response = await axios.post(`${getApiDomain()}/api/fiction/updatetiersettings`, {
                tier_settings: Object.values(tierSettings),
                fiction_id: fictionId
            });
            if (response.status === 200) {
                console.log('Tier settings saved successfully!');
                setTierSettingsMessage('Settings Saved Successfully');
            } else {
                console.error('Error saving tier settings:', response.statusText);
                setTierSettingsMessage(`Error: ${response.statusText}`);
            }
        } catch (error: unknown) {
            if (error instanceof Error) {
                console.error('Error saving tier settings:', error);
                setTierSettingsMessage(`Error: ${error.message}`);
            } else {
                console.error('Error saving tier settings:', error);
                setTierSettingsMessage('An unknown error occurred.');
            }
        } finally {
            setIsSavingTierSettings(false);
        }
    };

    return (
        <>
            <div className={currentStyles.mainContainer}>
                <div className={currentStyles.innerContent}>
                    {error ? (
                        <p>{error}</p>
                    ) : fiction ? (
                        <div className={currentStyles.fictionCard}>
                            <div className={currentStyles.fictionHeader}>
                                <div className={currentStyles.fictionRow}>
                                    <img 
                                        src={fiction.coverImage} 
                                        alt="Fiction Cover" 
                                        className={currentStyles.fictionCover} 
                                        onLoad={handleCoverImageLoad}
                                    />
                                    <div className={currentStyles.fictionButtons}>
                                        <button onClick={() => navigate(`/author-dashboard/chapters/new/${fictionId}`)}>Add Chapter</button>
                                        <button onClick={() => navigate(`/author-dashboard/settings/edit/${fictionId}`)}>Edit Fiction</button>
                                    </div>
                                </div>
                                <div className={currentStyles.fictionInfo}>
                                    <h1 className={currentStyles.fictionTitle}>{fiction.title}</h1>
                                    <div className={currentStyles.fictionSynopsis} dangerouslySetInnerHTML={{ __html: fiction.synopsis }} />
                                    <p className={currentStyles.fictionPatreon}>Patreon: <a href={`https://www.patreon.com/${fiction.patreon}`} style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}>{fiction.patreon}</a></p>
                                    <p className={currentStyles.fictionCreatedAt}>Created at: {new Date(fiction.created_at).toLocaleDateString()}</p>
                                </div>
                            </div>
                            <div className={currentStyles.infoContainer}>
                                {linkedPatreon ? (
                                    <div>
                                        <h2>Chapter Early Access Settings</h2>
                                        <p>The number represents how many chapters in advance each member can read. It is calculated by using the highest value. This is per fiction, not global.</p>
                                        <p>The math is: x = highest advance chapter amount, y = the amount of chapters the user can access, z = the amount of chapters locked to the user, counting from latest → x - y = z<br />If x = 10, y = 8 then 10 - 8 = 2, aka the user can access all <i>but</i> the latest two chapters.</p>

                                        <p>I.e. if you have Tier 1 with 2 advance chapters, and Tier 2 with 10 advance chapters, and your latest posted chapter is Chapter 30, it will automatically deduct that the early access chapters start from Chapter 21. So, Tier 1 will have access to 21 and 22, while Tier 2 will have access up to 30.</p>
                                        <p>Note: This does not cascade with higher tiers for now, the numbers are absolute, aka if you set a lower number to a higher priced tier, they will not be able to access chapters beyond that. So if you have a $3 tier at 3 chapters, a $5 at 5 chapters, a $10 at 10 chapters, and a $20 tier at 10 chapters, you need to set the values as 3, 5, 10, 10.</p>
                                        {(linkedPatreonData as any)?.tiers_data?.map((tier: any) => (
                                            tier.attributes.title.toLowerCase() !== 'free' && tierSettings && (
                                                <div key={tier.id} className={currentStyles.tierSetting}>
                                                    <h3>{tier.attributes.title}</h3>
                                                    <label>
                                                        <input
                                                            type="number"
                                                            value={tierSettings[tier.id]?.early_chapter_count || 0}
                                                            onChange={(e) => {
                                                                const value = Math.max(0, parseInt(e.target.value));
                                                                handleTierSettingChange(tier.id, value);
                                                            }}
                                                        />
                                                    </label>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                ) : (
                                    <h1>To Access Early Access Chapter settings, please link your patreon</h1>
                                )}
                                {linkedPatreon && (
                                    <button onClick={saveTierSettings} className={currentStyles.saveButton} disabled={isSavingTierSettings}>
                                        {isSavingTierSettings ? 'Saving...' : 'Save Settings'}
                                    </button>
                                )}
                                {tierSettingsMessage && (
                                    <p style={{ color: tierSettingsMessage.startsWith('Error') ? 'red' : 'green' }}>
                                        {tierSettingsMessage}
                                    </p>
                                )}
                            </div>
                        </div>
                    ) : (
                        <p style={{ 
                            backgroundColor: isDarkMode ? '#2e2e2e' : '#ffffff', 
                            color: isDarkMode ? '#ffffff' : '#000000',
                            borderRadius: '10px', 
                            padding: '10px' 
                        }}>
                            Loading fiction information...
                        </p>
                    )}
                </div>
            </div>
        </>
    );
}