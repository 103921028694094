import HomeContentView from "./HomeContentView";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import styles from "../styles/GlobalPageStyles.module.css";
import Loading from "../components/Loading";
import { Helmet } from "react-helmet-async";
import NavBar from "../components/NavBar";

export default function Roadmap() {
    const sessionContext = useSessionContext();

    if (sessionContext.loading === true) {
        return <Loading />;
    }

    return (
        <>
            <Helmet>
                <title>Roadmap/Changelog | Snowing Pine Stories</title>
            </Helmet>
            <div className={styles.fill} id={styles.homeContainer}>
                <NavBar />
                <HomeContentView />
            </div>
        </>
    );
}